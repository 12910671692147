import React, { useState, Fragment, useEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, EffectFade } from 'swiper';
import * as nearAPI from 'near-api-js';

import CardModalVIPLS from '../CardModalVIPLS';

import 'swiper/scss';
import "swiper/css/effect-fade";

import TMMPimg1 from '../../../assets/images/1.jpg'
import logo from '../../../assets/images/WL/logo.png'
import { ReactComponent as NearType } from '../../../assets/images/icon/near_type.svg'

import { useSelector } from 'react-redux';
import { callDalleService } from "../../../backend_api";
import { Web3Storage } from 'web3.storage';

const b64toBlob = (b64Data, contentType='', sliceSize=512) => {
  const byteCharacters = atob(b64Data);
  const byteArrays = [];
 
  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);
 
    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }
 
    const byteArray = new Uint8Array(byteNumbers);
 
    byteArrays.push(byteArray);
  }
 
  const blob = new Blob(byteArrays, {type: contentType});
  return blob;
}

function getAccessToken () {
  // If you're just testing, you can paste in a token
  // and uncomment the following line:
  // return 'paste-your-token-here'
  
  // In a real app, it's better to read an access token from an
  // environement variable or other configuration that's kept outside of
  // your code base. For this to work, you need to set the
  // WEB3STORAGE_TOKEN environment variable before you run your code.
  return 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJkaWQ6ZXRocjoweDQ0NjFkNGQ0NTgwMkVlNTQwRUVFZUFkM0ZCY2NFOWJDOTBEMDEyZmMiLCJpc3MiOiJ3ZWIzLXN0b3JhZ2UiLCJpYXQiOjE2NTg3ODgxMDAxMTksIm5hbWUiOiJocm1zYWkifQ.rIJCyp9fpwjTajk_w4BJbUheGPYmQOrXwFIi7uG330Y'
}
  
function makeStorageClient () {
  return new Web3Storage({ token: getAccessToken() })
}

const LiveAuctionVIPLS = () => {

  const nearWallet = useSelector(state => state.near.wallet);
  const nftContract = useSelector(state => state.near.nftContract);
  const [modalShow, setModalShow] = useState(false);
  const [slideTitle, setSlideTitle] = useState('NEAR Hub : GeNFT-v0.7.5');
  const [qtySold, setQtySold] = useState('-');
  const [tokens, setTokens] = useState([]);
  const [mintError, setMintError] = useState(false);
  const [isMinting, setIsMinting] = useState(false);
  const [generatedImages, setGeneratedImages] = useState([]);
  const [generatedImagesFormat, setGeneratedImagesFormat] = useState('jpeg');
  const [cid, setCid] = useState('');
  const [nftTitle, setNftTitle] = useState('');
  const [tokenPage, setTokenPage] = useState(0);
  const [nftQty, setNftQty] = useState(0);
  const [loading, setLoading] = useState(false);
  const [dalleURL, setDalleURL] = useState([]);
  
  useEffect(() => {
    const fetchConfig = async () => {
      const res = await fetch('https://api.npoint.io/f15b09d690a6620b65ad');
      const data = await res.json();            
      setDalleURL(data.servers[0]);
    }
    fetchConfig();
  }, []);

  const { utils } = nearAPI;

  useEffect(() => {
    if (nftContract) {
      nftContract.nft_total_supply().then((qty) => setQtySold(qty));
      if (nearWallet?.isSignedIn()) {
        nftContract.nft_supply_for_owner({account_id: nearWallet.getAccountId()}).then((qty) => {
          setNftQty(qty);
          setTokenPage(1);
          //const lastPage = Math.ceil(qty/6)-1;
          //setTokenPage(lastPage > 0 ? lastPage : 0);
          /*
          nftContract.nft_tokens_for_owner({
            account_id: nearWallet.getAccountId(),
            from_index: ''+(tokenPage*6),
            limit: 6,
          }).then((t) => { setTokens(t); });
          */
        });      
      }
    }
  }, [nftContract, nearWallet]);

  useEffect(() => {
    if (tokenPage > 0 && nftContract) {

      let limit = 6;
      let fromIndex = ( nftQty - (tokenPage*6) );
      if (fromIndex < 0) {
        limit = 6 - (fromIndex * -1);
        fromIndex = 0;        
      }

      nftContract.nft_tokens_for_owner({
        account_id: nearWallet.getAccountId(),
        from_index: ''+fromIndex,
        limit,
      }).then((t) => { 
        setTokens(tokens.concat(t.reverse())); 
        setLoading(false);
    });
    }
  }, [tokenPage, nftContract]);    


  useEffect(async () => {
      // cid has changed, so new images to mint
      if (cid) {

        const mediaList = generatedImages.map((img, ind) => {
            return 'https://'+cid+'.ipfs.dweb.link/'+(ind+1)+'.'+generatedImagesFormat;
        });

        const result = await nftContract.nft_mint(
          {
            token_id: "GeNFT-"+(new Date()),
            metadata: {
              title: nftTitle,
              description: "Nearhub GeNFT: "+nftTitle,
              media: mediaList[0],
            },
            receiver_id: nearWallet.getAccountId(),
          },
          "75000000000000",
          utils.format.parseNearAmount('0.1') 
        ).catch(err => {
          setMintError(true);
          setIsMinting(false);
        });
        setIsMinting(false);
        window.location.reload();
      }
  }, [cid]);

  const handlePurchase = async (aiInput) => {
    setNftTitle(aiInput);
    setMintError(false);
    setIsMinting(true);
    callDalleService(dalleURL, aiInput, 1)
      .then(res => { 
        if (res?.serverResponse?.generatedImgs) {
          setGeneratedImages(res.serverResponse.generatedImgs);
          setGeneratedImagesFormat(res.serverResponse.generatedImgsFormat);

          const files = res.serverResponse.generatedImgs.map((img,ind) => {
            const blob = b64toBlob(img, 'image/' + res.serverResponse.generatedImgsFormat);
            return new File([blob], (ind+1)+'.'+res.serverResponse.generatedImgsFormat);
          });
          
          const client = makeStorageClient();
          client.put(files).then(cid => setCid(cid));
        }
        
        /*
        near call $ID nft_mint '{"token_id": "0", "receiver_id": "'$ID'", "token_metadata": { "title": "Olympus Mons", "description": "Tallest mountain in charted solar system", "media": "https://upload.wikimedia.org/wikipedia/commons/thumb/0/00/Olympus_Mons_alt.jpg/1024px-Olympus_Mons_alt.jpg", "copies": 1}}' --accountId $ID --deposit 0.1
        */
      });
  }

  const nextPage = async () => {
    if (!loading) {
      setTokenPage(tokenPage + 1);
      setLoading(true);
    }
  }

  return (
    <Fragment>
      <div className="row">
        <div className="col-md-2"></div>
        <div className="col-md-8" style={{ marginTop: 100, zIndex: 22, padding: 10 }}>
          <div className="heading-live-auctions" style={{ alignItems: 'center' }}>
            <h2 className="tf-title" style={{ marginLeft: 0 }}>NEAR Hub : GeNFT</h2>   
            <div className="button-place-bid" style={{ zIndex: 22, marginRight: 16 }}>
              <button onClick={() => setModalShow(true)} className="sc-button style-place-bid style bag fl-button pri-3">
                <span>Generate and Mint a GeNFT</span>
              </button>
            </div>                           
          </div>
          <span style={{ fontSize:16, marginLeft: 20 }}>{qtySold} created</span>  
        </div>
        <div className="col-md-2"></div>
      </div>

      <section className="tf-section live-auctions">
        <div className="grid-container">
        <div className="row">
          <div className="wrapper-grid">
            { tokens && tokens.length > 0 && tokens./*sort((a,b) => Date.parse(a.token_id.replace('GeNFT-','')) < Date.parse(b.token_id.replace('GeNFT-','')) ).*/map((nft, ind) => {
              return ( 
                <div key={'nft'+ind} className="sc-card-product" style={{ width: '280px', height: '450px', margin: '0 auto' }}>
                  <h3 style={{textAlign: 'center', marginBottom: 20}}>Your GeNFT</h3>
                  <div className="card-media">
                    <img src={nft.metadata.media} alt={nft.metadata.title} />
                  </div>
                  <div className="card-title" style={{flexDirection: 'column'}}>
                    <h5><a>{nft.metadata.title}</a></h5>
                    <p style={{fontSize: 14, lineHeight: 1.5}}>{nft.metadata.description}</p>
                  </div>
                  <div className="meta-info">
                    <div className="author">
                      <div className="info">
                        <span>Owner</span>
                        <h6><a to="/authors-02">{nft.metadata.owner_id}</a></h6>
                      </div>
                    </div>                                                        
                  </div>
                </div>
              )})
            }            
          </div>          
        </div>
        {
          tokens && tokens.length > 0 && tokens.length < nftQty && (                
            <div className='flex' style={{ justifyContent: 'center', marginTop: 20 }}>
              <div onClick={nextPage} 
                   className='flex text-center w-full p-4 m-4 rounded text-xl pointer' 
                   style={{background: 'var(--primary-color)', fontSize: 18, fontWeight: 600, borderRadius: 5}}
              >
                {
                  loading ? 'Loading...' : 'Load more'
                }
              </div>
            </div>
          )
        }
        </div>

        <div className="themesflat-container">
          <div className="row">
            <div className="col-md-2"></div>
            <div className="col-md-8">                            

              <div className="swiper-container show-shadow carousel auctions">
                <div className="swiper-wrapper">
                  <div className="swiper-slide">
                    <div className="slider-item">
                      <div className="sc-card-product">

                        { 
                          <div className="button-place-bid" style={{zIndex: 22}}>
                            <button onClick={() => setModalShow(true)} className="sc-button style-place-bid style bag fl-button pri-3" style={{width:'100%', marginBottom: 20, borderRadius: 15}}><span>Generate and Mint a GeNFT</span></button>
                          </div>
                        }

                        <div className="card-media">
                                                  
                        <Swiper
                          spaceBetween={10}
                          effect={"fade"}
                          modules={[EffectFade, Autoplay]}              
                          loop
                          autoplay={{
                            delay: 1,
                            disableOnInteraction: false,
                            pauseOnMouseEnter: false
                          }}
                          speed= {3000}
                          style={{borderRadius: '5px'}}
                          onSlideChangeTransitionStart={(e) => {
                            switch(e.realIndex) {
                              case 0: setSlideTitle('NEAR Hub : GeNFT');
                              break;
                              default:
                              break;                                                                  
                            }                                                                
                          }}
                        >
                          <SwiperSlide><img src={TMMPimg1} alt="NEAR Hub : GeNFT" /></SwiperSlide>                                                            
                        </Swiper>
                        { 
                          <div className="button-place-bid" style={{zIndex: 22}}>
                            <button onClick={() => setModalShow(true)} className="sc-button style-place-bid style bag fl-button pri-3"><span>Generate and Mint a GeNFT</span></button>
                          </div>
                        }
                      </div>

                      { 
                        <div className="button-place-bid" style={{zIndex: 22}}>
                          <button onClick={() => setModalShow(true)} className="sc-button style-place-bid style bag fl-button pri-3" style={{width:'100%', marginBottom: 20, borderRadius: 15}}><span>Mint NFT</span></button>
                        </div>
                      }
                      <div className="card-title">
                        <h5><a>{slideTitle}</a></h5>
                      </div>
                      <div className="meta-info">
                        <div className="author">
                          <div className="avatar" style={{borderRadius:0}}>
                            <img src={logo} alt="NEAR Hub : GeNFT" />
                          </div>
                          <div className="info">
                            <span>Creator</span>
                            <h6><a to="/authors-02">NEAR Hub : GeNFT</a> </h6>
                          </div>
                        </div>
                        <div className="price" style={{}}>                                                                        
                          <h5 style={{fontSize:40, display:'flex', alignItems: 'center', marginRight: 10}}>
                          </h5>
                        </div>
                      </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>         
            </div>
            <div className="col-md-2"></div>
          </div>
        </div>       
      </section>

      <CardModalVIPLS
        show={modalShow}
        onHide={() => setModalShow(false)}
        onPurchase={handlePurchase}       
        mintError={mintError}    
        isMinting={isMinting}     
      />
    </Fragment>
  );
}

export default LiveAuctionVIPLS;
