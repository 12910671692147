import React from 'react';
import { Link } from 'react-router-dom'
import { Modal } from "react-bootstrap";

import { ReactComponent as NearType } from '../../assets/images/icon/near_type.svg'

import NEAR from '../../constants/near';
import { useSelector } from 'react-redux';
import { useState } from 'react';

const CardModalVIPLS = (props) => {    
  const nearWallet = useSelector(state => state.near.wallet);
  const [aiIn, setAiIn] = useState('');
  
  const [qty, setQty] = useState(1);

  const login = () => {
    nearWallet?.requestSignIn(
      NEAR.NFT_CONTRACT_NAME,
      'Hearhub AI Art'
    );
  }

  return (
    <Modal
      show={props.show}
      onHide={props.onHide}
    >
      <Modal.Header closeButton></Modal.Header>

      <div className="modal-body space-y-20 pd-40">
        <h3>Generate and Mint AI Art GeNFT</h3>

        <input type="text" className="form-control" placeholder="What do you want to generate?" value={aiIn} onChange={(e) => setAiIn(e.target.value)} />

        { 
          /*
          <input type="number" className="form-control" max={4} min={1} placeholder="How many do you want to generate?" value={qty} onChange={(e) => setQty(e.target.value)} />
          */ 
        }

        <div className="price" style={{}}>         
          <h5 style={{fontSize: 40, display: 'flex', alignItems: 'center', marginBottom: 13, justifyContent: 'center'}}>0.1 <NearType style={{display:'inline', fill: '#fff', height: 15, marginLeft: 10}} /></h5>                                                               
          <p className="text-center">5% Royalty</p>
        </div>

        { props.mintError &&
          <div style={{textAlign: 'center', fontSize: 14, fontWeight: 'bold'}}>Error minting NFT</div>
        }
        { nearWallet?.isSignedIn() && nearWallet.getAccountId() && !props.isMinting &&
          <span onClick={() => props.onPurchase(aiIn) } className="btn btn-primary" data-toggle="modal" data-target="#popup_bid_success" data-dismiss="modal" aria-label="Purchase NFT">Mint AI Art NFT</span>
        }            
        { nearWallet?.isSignedIn() && nearWallet.getAccountId() && props.isMinting &&
          <span className="btn btn-primary" data-toggle="modal" data-target="#popup_bid_success" data-dismiss="modal" aria-label="Purchase NFT">Generating and Minting...</span>
        }            
        { nearWallet?.isSignedIn() !== true &&
          <span onClick={login} className="btn btn-primary" data-toggle="modal" data-target="#popup_bid_success" data-dismiss="modal" aria-label="Connect Wallet">Connect Wallet</span>
        }

        { nearWallet?.isSignedIn() && nearWallet.getAccountId() && props.isMinting &&
          <div style={{ textAlign: 'center', fontSize: 14, fontWeight: 'bold' }}>
            Great idea creating it now. The more descriptive you are, I will be able to better understand your vision. Almost finished now :)
          </div>
        }
      </div>
    </Modal>
  );
};

export default CardModalVIPLS;
